<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-white py-8">
    <slot />
  </div>
</template>

<script>
export default {
  name: "CryptoContainer",
};
</script>
